const t = {
    icon: 'fa-money',
    menus: {
        main: {section: 'files', priority: 30},
    },
    // new: {
    //     attributes: [
    //         'externalId','project', 'customer', 'tenant','price','description','url'
    //     ],
    //     content: [
    //         ['row', [
    //             ['block', [
    //                 '$tenant!', '!$customer!', '$project!','$price!','$description!',
    //                 '$externalId','$url'
    //             ]],
    //         ]],
    //         '$submit',
    //     ],
    // },
    display: {
        properties: [
            'id', 'customer', 'project','price','description', 'tenant','externalId',
            'url','locked'
        ],
    },
    list: {
        props: {
            search: false,
            searchSwitch: false,
        },
        icon: 'fa-money-bill-wave',
        columns: [
            {id: 'id', label: 'column_id_label'},
            // {id: 'tenantName', label: 'column_tenant_label'},
            // {id: 'tenantCode', label: 'column_tenantcode_label'},
            // {id: 'customerCode', label: 'column_customercode_label'},
            // {id: 'project', label: 'column_project_label'},
             {id: 'price', label: 'column_price_label'},
             {id: 'description', label: 'column_description_label'},
             {id: 'url', label: 'column_url_label'},
            // {id: 'externalId', label: 'column_externalId_label'},
        ],
        globalActions: [],
        actions: ['display'],
    },
};

export default t;