const translations = {
    'fr-FR': {
        translation: {

            locale_fr_fr: 'Français (France)',
            locale_fr_lu: 'Français (Luxembourg)',
            locale_fr_mc: 'Français (Monaco)',
            locale_fr_be: 'Français (Belgique)',
            locale_fr_ca: 'Français (Canada)',
            locale_en_gb: 'Anglais (Grande Bretagne)',
            locale_en_us: 'Anglais (Etats-Unis)',
            locale_it_it: 'Italien (Italie)',
            locale_es_es: 'Espagnol (Espagne)',

            country_fr: 'France',
            country_lu: 'Luxembourg',
            country_mc: 'Monaco',
            country_be: 'Belgique',
            country_ca: 'Canada',
            country_gb: 'Grande Bretagne',
            country_us: 'Etats-Unis',
            country_it: 'Italie',
            country_es: 'Espagne',
            country_pt: 'Portugal',
            country_ch: 'Suisse',
            country_re: 'Réunion (Ile de)',

            field_matricule_label: 'Matricule',
            field_matricule_placeholder: 'Ex: M01A23',
            field_matricule_helper: 'Ex: M01A23',
            field_mooncardid_label: "Mooncard Id",
            field_externalid_label: 'ID externe',
            field_contract_label: 'Contrat',
            field_title_label : "Titre",
            field_title_placeholder : 'Ceci est un titre',
            field_title_helper : "Saisissez le titre ici",
            field_locale_label: 'Langue',
            field_locale_helper: 'Langue',
            field_locale_placeholder: 'Langue',
            field_main_file: 'Fichier',
            field_country_label: 'Pays',
            field_country_helper: 'Pays',
            field_country_placeholder: 'Pays',
            field_tenant_label: 'Tenant',
            field_employee_label: 'Porté',
            field_customer_label: 'Client',
            field_workedday_label: "Jours travaillés",        
            field_keyword_status_label: 'Status',
            field_keyword_status_placeholder: 'Status',
            field_keyword_status_helper: 'Status',
            field_owner_label: "Propriétaire",
            field_owner_placeholder: "Propriétaire",
            field_owner_helper: "Propriétaire",
            field_data_label: "Data",
            field_data_placeholder: "Data",
            field_data_helper: "Data",
            field_params_label: "Paramètres",
            field_params_placeholder: "Paramètres",
            field_params_helper: "Paramètres",
            field_report_label: "Report",
            field_report_placeholder: "Report",
            field_report_helper: "Report",
            field_domain_label: "Domaine",
            field_domain_placeholder: "Domaine",
            field_domain_helper: "Domaine",
            field_datestart_label: "Début",
            field_dateend_label: "Fin",
            field_sender_label: "Expéditeur",
            field_sender_placeholder: "Expéditeur",
            field_sender_helper: "Expéditeur",
            field_recipient_label: "Destinataire",
            field_recipient_placeholder: "Destinataire",
            field_recipient_helper: "Destinataire",
            field_template_label: "Template",
            field_template_placeholder: "Template",
            field_template_helper: "Template",
            field_variables_label: "Variables",
            field_variables_placeholder: "Variables",
            field_variables_helper: "Variables",
            type_email_label: "Email",
            type_sms_label: "SMS",
            type_push_label: "Push",
            field_values_label: "Valeurs",
            field_result_label: "Résultat",
            field_strategy_label: 'Stratégie',
            field_strategy_helper: 'Stratégie',
            field_strategy_placeholder: 'Stratégie',
            field_status_label: 'Statut',
            field_status_helper: 'Statut',
            field_status_placeholder: 'Statut',
            field_rate_label: "TJM",
            field_rate_helper: "Tarif journalier",
            field_autostart_label: "Démarrage automatique",
            field_period_percent_label: "Frais de gestion sur la période",
            field_period_turnover_label: "Field_period_turnover_label",
            field_owneremail_label:"Email",
            field_ownername_label:"Nom",
            field_ownerfirstname_label:"Prénom",
            field_salary_label: "Salaire ",
            field_autodelete_label: "Suppression automatique",
            field_baseaccount_label: "Field_baseaccount_label",
            field_featured_label: "En vedette",
            field_peramount_label: "Montant du PER",
            Field_peeamount_label: "Montant du PEE",
            field_employer_contributions_label: "Charges Patronales",
            field_employee_contributions_label: "Charges Salariales",
            field_tags_label: "Tags",
            field_tags_helper: "Tags",
            field_maintheme_label: 'Thème principal',
            field_maintheme_placeholder : 'thème principal',
            field_maintheme_helper : 'Selectionnez le thème principal',

            searchbar_placeholder: "Rechercher...",
            searchbar_invalid_input: "Code invalide",

            field_varcolorprimary_label: 'Principale',
            field_varcolorsecondary_label: 'Secondaire',
            field_varcolornavprimary_label: 'Principale Nav',
            field_varcolornavsecondary_label: 'Secondaire Nav',
            field_varcolorpanelprimary_label: 'Principale Panels',
            field_varcolorpanelsecondary_label: 'Secondaire Panels',
            field_varcolorchoiceprimary_label: 'Principale Choix',
            field_varcolorchoicesecondary_label: 'Secondaire Choix',
            field_varcolorfooterprimary_label: 'Principale Footer',
            field_varcolorfootersecondary_label: 'Secondaire Footer',
            field_varcolorheading_label: 'Principale Headings',
            field_varcolortitle_label: 'Principale Titres',
            field_varcolorlogo_label: 'Secondaire Logo',

           
            notification_screen_general_title: 'Général',
            notification_screen_desktop_title: 'Desktop',

            column_role_label: 'Rôle',
            column_progress_label: 'Progression',
            column_id_label: 'Identifiant',   
            column_date_label: 'Date',
            column_datestart_label: 'Date de début',
            column_dateend_label: 'Date de fin',
            column_email_label: 'Email',
            column_employee_label: 'Porté',
            column_mainFile_label: 'Bulletin',
            column_name_label: 'Nom',
            column_lastname_label: 'Nom de famille',
            column_firstname_label: 'Prénom',
            column_customer_label: 'Client',
            column_customercode_label: 'Code Client',
            column_project_label: 'Projet',
            column_employeename_label: 'Porté',
            column_amount_label: 'Montant',
            column_createdat_label: 'Date de création',
            column_description_label: 'Description',
            column_updatedat_label: 'Date de modification',
            column_workedday_label: 'Jours travaillés',
            column_actions_label: 'Actions',
            column_code_label: 'Code',
            column_organization_code_label: 'Organisation',
            column_key_label: 'Key',
            column_keywords_label: 'Mots Clefs',
            column_phone_label: 'Téléphone',
            column_payment_amount_label: 'Montant',
            column_payment_currency_label: 'Devise',
            column_donation_label: 'Don',
            column_owner_label: 'Propriétaire',
            column_owneremail_label: 'Email', 
            column_type_label: 'Type',
            column_offer_label: 'Offre',
            column_partner_label: 'Partenaire',
            column_tenant_label: 'Tenant',
            column_tenantname_label: 'Nom du tenant',
            column_tenantcode_label: 'Code du tenant',
            column_url_label: 'URL',
            column_redirect_public_url_label: 'URL',
            column_redirect_url_label: 'Cible',
            column_main_image_label: "Image Principale",
            column_rank_label: "Rang",
            column_strategy_label: 'Stratégie',
            column_status_label: 'Status',
            column_quantity_label: "Quantité",
            column_sender_label: 'Expéditeur',
            column_rate_label: 'TJM',
            column_recipient_label: 'Destinataire',
            column_subject_label: 'Subject',
            column_result_label: 'Resultat',
            column_values_label: 'Valeurs',
            column_stataccountamount_label: 'Compte de reserve',
            column_contract_label: 'Contrat',

            screen_generic_list_table_empty:'Pas de résultats',

            screen_expenses_label: 'Dépenses',
            screen_expense_label: 'Dépense',

            screen_ereports_label: 'Feuilles de temps',
            screen_ereport_new_label: 'Nouvelle feuille de temps',

            screen_employee_label: 'Employé',
            screen_payslips_label: 'Bulletins de Salaire',
            screen_payslip_label: 'Bulletin de Salaire',
            screen_contract_label: 'Contrat {{id}}',
            screen_contract_new_label: 'Nouveau contrat',
            screen_contract_edit_label: "Modification du contrat",
            screen_contracts_label: 'Contrats',
            screen_eperiod_new_label: 'Nouvelle Période',
            screen_eperiods_label:'Périodes Salariales',
            screen_eperiod_edit_label: 'Modification d\'une période salarial',
            screen_eperiod_label: 'E Période',
            screen_home_label: 'Accueil',
            screen_configuration_label: 'Configuration {{id}}',
            screen_configurations_label: 'Configurations',
            screen_einvoices_label: 'Factures',
            screen_project_label: 'Projet {{id}}',
            screen_project_new_label: 'Nouveau projet',
            screen_project_edit_label: 'Modification du projet',
            screen_projects_label: 'Projets',
            screen_input_label: 'Champ {{id}}',
            screen_input_new_label: 'Nouveau champ',
            screen_input_edit_label: 'Modification du champ',
            screen_inputs_label: 'Champs',
            screen_simulations_label: 'Simulations',
            screen_simulation_new_label:'Nouvelle simulation',
            screen_simulation_edit_label:'Modification de la simulation',
            screen_tag_label: 'Tag {{id}}',
            screen_tag_new_label: 'New tag',
            screen_tag_edit_label: 'Update tag',
            screen_tags_label: 'Tags',
            screen_user_profile_label: 'Mon compte',
            screen_users_label: 'Utilisateurs',
            screen_simulation_label: 'Simulations',

            menu_expenses_label:'Dépenses',
            menu_einvoices_label:'Factures',
            menu_ereports_label:'mes feuilles de temps',
            menu_section_files_label:'Consulter',
            menu_section_customers_label: 'Clients',
            menu_section_activity_label: 'Activité',
            menu_section_settings_label: 'Paramétrage',
            menu_section_stats_label:'Mes stats',
            menu_section_tools_label: 'Mes Outils',
            menu_section_payslips_label: 'Bulletins de salaire',
            menu_simulations_label:'Simulations',
            menu_payslips_label: 'Bulletins de salaire',
            menu_eperiods_label: 'Périodes',
            menu_customers_label: 'Entreprises Clientes',
            menu_cperiods_label: 'Périodes',
            menu_dashboard_label: 'Tableau de Bord',
            menu_notifications_label: "Notifications",
            menu_tags_label: "Tags",
            menu_contracts_label: "Contrats",
 
            button_abort_label: 'Abort',
            button_add_label: 'Ajouter',
            button_delete_label: 'Supprimer',
            button_display_label: 'Afficher',
            button_cancel_label: 'Annuler',
            button_clear_file_label: 'Effacer',
            button_confirm_label: 'Confirmer',
            button_delete_contract_label:'Supprimer',
            button_delete_simulation_label: 'Supprimer',
            button_delete_project_label: 'Supprimer',
            button_delete_payslip_label: 'Supprimer',
            button_edit_label: 'Modifier',
            button_pause_label: 'Pause',
            button_regenerate_label: 'Regénérer',
            button_reset_label: 'Reset',
            button_resend_label: 'Renvoyer',
            button_start_label: 'Lancer',
            button_processing_label: 'En cours...',
           
            form_edit_current_user_title: 'Modifier mes informations personnelles',
            form_edit_current_user_subtitle: 'Vous pouvez mettre à jour les données du compte ci-dessous',

            modal_confirm_delete_simulation_title: 'Confirmation de la suppression',
            modal_confirm_delete_simulation_text: 'Souhaitez-vous vraiment supprimer cette simulation ?',

        
            user_menu_profile_label: 'Mon compte',
        }
    },
}
export default translations
