const t = {
    icon: 'fa-file',
    menus: {
        main: {section: 'files', priority: 1},
    },
    display: {
        properties: [
           'customer','project','tenant','dateStart','dateEnd',
        ],
    },
    list: {
        props: {
            search: false,
            searchSwitch: false,
        },
        icon: 'fa-chart-column',
        columns: [
            {id: 'contractName', label: 'column_contract_label'},
            {id: 'customerName', label: 'column_customer_label'},
            {id: 'projectName', label: 'column_project_label'},
            {id: 'workedDay', label: 'column_workedday_label'},
            {id: 'dateStart', label: 'column_datestart_label',format: (v: any) => v ? new Date(parseInt(v)).toLocaleString('FR-fr',{ year: 'numeric', month: 'long', day: 'numeric',}) : 'undefined'},
            {id: 'dateEnd', label: 'column_dateend_label',format: (v: any) => v ? new Date(parseInt(v)).toLocaleString('FR-fr',{ year: 'numeric', month: 'long', day: 'numeric',}) : 'undefined'},
        ],
        actions: ['display'],
    },
};

export default t;