const t = {
    icon: 'fa-file',
    menus: {
        main: {section: 'files', priority: 30},
    },
    new: {
        attributes: [
             'contract','month','workedDay','cra'
        ],
        content: [
            ['row', [
                ['block', [
                    '$contract!','$month!','$workedDay!','$cra!',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'customer', 'project','price','description', 'tenant','externalId',
            'url','locked'
        ],
    },
    list: {
        props: {
            search: false,
            searchSwitch: false,
        },
        icon: 'fa-clock',
        columns: [
            {id: 'tenantName', label: 'column_tenant_label'},
            {id: 'tenantCode', label: 'column_tenantcode_label'},
            {id: 'customerCode', label: 'column_customercode_label'},
            {id: 'project', label: 'column_project_label'},
            {id: 'price', label: 'column_price_label'},
            {id: 'description', label: 'column_description_label'},
            {id: 'url', label: 'column_url_label'},
            {id: 'externalId', label: 'column_externalId_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;