const t = {
    icon: 'fa-user-tie',
    menus: {
        main: {section: 'files', priority: 10},
    },
    new: {
        attributes: [
            'customer', 'employee',
        ],
        content: [
            ['row', [
                ['block', [
                     '$customer!', '$employee!','$project!'
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'customer', 'employee', 'project','rate',
        ],
        content: [
            ['row', [
                ['block', [
                     '$code:readonly!','$project:readonly!', '$employee!', '$customer!','$rate!',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        properties: [
            'id', ['code'], 'customer','project', 'employee', ['rate' ]
        ],
    },
    list: {
        props: {
            search: false,
            searchSwitch: false,
        },
        icon: 'fa-handshake-simple',
        columns: [
            {id: 'customerName', label: 'column_customer_label'},
            {id: 'rate', label: 'column_rate_label', format: (v: any) => v ? v/100: undefined},
            {id: 'dateStart', label: 'column_datestart_label', format:  (v: any) => v ? new Date(parseInt(v)).toLocaleDateString() : 'undefined'},
            {id: 'dateEnd', label: 'column_dateend_label', format: (v: any) => v ? new Date(parseInt(v)).toLocaleDateString() : 'undefined'},
        ],
        globalActions: ['add'],
        actions: ['display'],
    },
};

export default t;