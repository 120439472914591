const t = {
    icon: 'fa-user-tie',
    menus: {
        main: {section: 'tools', priority: 10},
    },
    new: {
        attributes: [
            'type', 'values', 'result','ownerEmail', 'ownerName', 'ownerFirstName'
        ],
        content: [
            ['row', [
                ['block', [
                    '$type!', '$values!', '$result:readonly', '$ownerEmail!', '$ownerName', '$ownerFirstName'
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'tenant', 'type', 'values', 'result',
        ],
        content: [
            ['row', [
                ['block', [
                    '$tenant:readonly!', '$status:readonly', '$type:readonly!', '$values!', '$result',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        properties: [
            'id', 'date',  'type', 'values', 'result', 'tenant', 'failureDetails', 'ownerEmail', 'ownerName', 'ownerFirstName'
        ],
    },
    list: {
        props: {
            search: false,
            searchSwitch: false,
        },
        icon: 'fa-award',
        columns: [
            {id: 'status', label: 'column_status_label'},
            {id: 'ownerEmail', label: 'column_owneremail_label'},
            {id: 'createdAt', label: 'column_date_label',format: (v: any) => v ? new Date(parseInt(v)).toLocaleString('FR-fr',{ year: 'numeric', month: 'long', day: 'numeric',}) : 'undefined'},
            {id: 'type', label: 'column_type_label'},
            {id: 'values', label: 'column_values_label'},
            {id: 'result', label: 'column_result_label'},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;