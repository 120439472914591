import {red} from '@material-ui/core/colors';
import buildMenus from "@genstackio/react-admin-core/lib/utils/buildMenus";
import crudRoutes from "@genstackio/react-admin-core/lib/utils/crudRoutes";
import buildBreadcrumbs from "@genstackio/react-admin-core/lib/utils/buildBreadcrumbs";
import buildUploader from "@genstackio/react-admin-core/lib/utils/buildUploader";
import buildLists from "@genstackio/react-admin-core/lib/utils/buildLists";
import * as types from './types';

export const app = {
    prefix: 'g8w_employee',
    endpoint: process.env.REACT_APP_API_ENDPOINT,
    locales: ['fr-FR'],
    requiredRoles: ['user'],
};
export const apiOptions = {
    timeout: 15000,
};
export const logos = {
    logo: {url: 'logo-GC.png', alt: 'G8W'},
    logoFull: {url: 'logo-GC.png', alt: 'G8W'},
};

export const dataArrayFormat = (data: any) => {
    return [].concat(data).sort((a: any, b: any) => a.dateStart < b.dateStart ? 1 : -1);
}

export const statementLabels = ["Mois","Contrat","TJM Consultant","Taux de gestion GW","Nb de jours facturés","Chiffre d'affaires","Frais de gestion gw","Frais pro","Commission apporteur d'affaire","Compte de réserve brut","Charges patronales","Charges Salariales","Salaire net avant impôts","Impôts sur le revenu","Salaire versé","Pee","Per","Autre"]
export const mobileStatementLabels = ["Mois","Contrat","TJM Consultant","Nb de jours facturés","Chiffre d'affaires","Compte de réserve brut","Salaire versé"]

export const color = (val:number,type:string) =>{
    if(type === 'mobile') {
        switch(val) {
            case 0: return "text-primary";
            case 1:case 3: case 5: return "bg-primary text-clear"
            case 2: return "text-success"
            case 4: return "text-blue bg-lightblue font-bold"
            case 6: return "bg-lightgreen text-green font-bold"
        }
    } else if (type === 'desktop') {
        switch(val) {
            case 1: return 'text-primary'
            case 2: case 4:  case 16: case 18: return 'text-clear bg-primary'
            case 8: case 12: case 14: return 'bg-primary text-lightred'
            case 3:case 13: return "text-green";
            case 6:case 15: return "bg-lightgreen text-green font-bold";
            case 7: case 9:  case 11: return "text-lightred";
            case 10: return "bg-lightblue text-blue font-bold";
            default: return "text-primary";
        }
    }
};

export const currency = (val:number, type:string) => {
    if (type === 'mobile') {
        switch(val) {
            case 0: case 1: return "";
            default: return "€";
        }
    } else if (type === 'desktop') {
        switch(val) {
            case 1: case 2: case 5: case 18: return "";
            case 4: return "%";
            default: return "€";
        }
    }
};

export const nFmt = (val:number) => {
        return val?.toLocaleString('fr-FR');
};

export const uploader = buildUploader(process.env.REACT_APP_UPLOADS_ENDPOINT);
export const theme = {
    tailwind: {},
    mui: {
        palette: {
            primary: {
                main: 'rgb(16,23,96)',
            },
            secondary: {
                main: 'rgb(250,16,105)',
            },
            error: {
                main: red.A400,
            },
            background: {
                default: '#FFFFFF',
            },
        },
    },
    theme: '',
};
export const ambiance = {
    sectionHeaderCorner: 'rounded-xxsmall',
    menuItemCorner: 'rounded-xsmall',
    menuFoldCorner: 'rounded-xxxsmall',
    buttonCorner: 'rounded-xsmall',
};
export const routes = [
    {path: "/user/forgot-password", screen: 'forgot_password', secured: false},
    {path: '/', exact: false, screen: 'app', routes: [
            {path: '/', screen: 'home', exact: true},
            {path: '/missions', screen: 'contracts', exact: true},
            {path: '/payslips', screen: 'payslips', exact: true},
            {path: '/statements', screen: 'statements', exact: true},
            {path: '/', exact: false, screen: 'crud_app', routes: [
                    ...crudRoutes(types),
                    {path: "/user/profile/edit", screen: 'user/edit_current_user'},
                    {path: "/user/profile", screen: 'user/user_profile'},
                    {screen: 'not_found', exact: false, secured: false},
                ]},
            {screen: 'not_found', exact: false, secured: false},
        ]},
    {screen: 'not_found', exact: false, secured: false},
];
export const lists = buildLists(types);
export const breadcrumbs = buildBreadcrumbs(types, ({t}: any) => ({
    'user-profile': [
        {label: t('screen_home_label'), target: '/'},
        {label: t('screen_user_profile_label'), target: '/user/profile'},
    ],
    default: [
        {label: t('screen_home_label'), target: '/'},
    ],
}));
export const menus = buildMenus(types, {
    main: {
        tools:  {
            items:({t}: {t: Function}) =>[
                {label: t('Nouvelle feuille de temps'), type: 'item',icon: 'fa-plus', activePrefix: '/ereport/new',  target: '/ereport/new'},
            ]
        },
        files: {
            items: ({t}: {t: Function}) => [
                {label: t('mes missions'), type: 'item',icon: 'fa-user-tie', activePrefix: '/missions',  target: '/missions'},
                {label: t('mes bulletins'), type: 'item',icon: 'fa-file', activePrefix: '/payslips',  target: '/payslips'},
                {label: t('mes décomptes'), type: 'item',icon: 'fa-folder-plus', activePrefix: '/statements',  target: '/statements'},
            ],
        },
        settings:  {},
    },
    user: {
        '-': {
            items: ({t}: {t: Function}) => [
                {label: t('user_menu_profile_label'), type: 'item', target: '/user/profile'},
            ],
        }
    }
});
export * as queries from './queries';
export {default as fields} from './fields';
export {default as icons} from './icons';
export {default as translations} from './translations';
export {default as importers} from './importers';